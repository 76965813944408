import Layout from "./components/Layout/Layout";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Education from "./pages/Educatons/Education";
import Projects from "./pages/Projects/Projects";
import Techstack from "./pages/Techstack/Techstack";
import WorkExp from "./pages/workExp/WorkExp";
import { useTheme } from "./context/ThemeContext";
import Tada from "react-reveal/Tada";
import MobileNav from "./components/MobileNav/MobileNav";

function App() {
    const [theme] = useTheme();
    return (
        <>
            <div id={theme}>
                <MobileNav/>
                <Layout />
                <div className="container">
                    <About />
                    <Education />
                    <Techstack />
                    <Projects />
                    <WorkExp />
                    <Contact />
                </div>
                <div className="footer pb-3 ms-3">
                    <Tada>
                        <h4 className="text-center">
                            &copy; Asad {new Date().getFullYear()}. All Rights Reserved.
                        </h4>
                    </Tada>
                </div>
            </div>
            
        </>
    );
}

export default App;
