import React from 'react';
import "./Projects.css"
import Spin from 'react-reveal/Spin';

const Projects = () => {
  return (
    <>
      <div className="container project" id='projects'>
        <h2 className='col-12 mt-3 mb-1 text-center text-uppercase'>Some Of My Projects</h2>
        <hr />
        <p className='pb-3 text-center'>Here are my top three recent projects, which I developed entirely on my own using MongoDB, Express, React, Node.js, and other technologies. You can explore the live applications and their source code through the provided links.</p>
        {/*card design */}
        <div className="row" id="ads">
          <Spin>
          <div className="col-md-4">
            <div className="card rounded">
              <div className="card-image">
                <span className="card-notify-badge">Full Stack</span>
                <img src="https://unctad.org/sites/default/files/2021-03/2021-03-15_eCommerceCOVID19report-1-1220x675px.jpg" alt="project 1" />
              </div>
              <div className="card-image-overlay m-auto mt-3">
                <span className="card-detail-badge">Node</span>
                <span className="card-detail-badge">Express</span>
                <span className="card-detail-badge">React</span>
                <span className="card-detail-badge">MongoDB</span>
              </div>
              <div className="card-body text-center">
                <div className="ad-title m-auto">
                  <h5 className='text-uppercase'>E-commerce website</h5>
                </div>
                <a href="#" className="ad-btn">View</a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card rounded">
              <div className="card-image">
                <span className="card-notify-badge">FullStack</span>
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR356D-1YtSagN4-_ZdjZ5H9o6PKUO4h12dvw&s" alt="project 2" />
              </div>
              <div className="card-image-overlay m-auto mt-3">
                <span className="card-detail-badge">Bootstrap</span>
                <span className="card-detail-badge">Node</span>
                <span className="card-detail-badge">Express</span>
                <span className="card-detail-badge">MongoDB</span>
              </div>
              <div className="card-body text-center">
                <div className="ad-title m-auto">
                  <h5 className='text-uppercase'>Inventory Management App</h5>
                </div>
                <a href="#" className="ad-btn">View</a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card rounded">
              <div className="card-image">
                <span className="card-notify-badge">Backend</span>
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeKCas6LwjH9_xXh9LY18xU5oH5ROonV6VAw&s" alt="project 1" />
              </div>
              <div className="card-image-overlay m-auto mt-3">
                <span className="card-detail-badge">Node</span>
                <span className="card-detail-badge">Express</span>
                <span className="card-detail-badge">NoSQL</span>
              </div>
              <div className="card-body text-center">
                <div className="ad-title m-auto">
                  <h5 className='text-uppercase'>URL Shortner App</h5>
                </div>
                <a href="view" className="ad-btn">View</a>
              </div>
            </div>
          </div>
          </Spin>
        </div>
        
      </div>
    </>
  )
}

export default Projects