import React from "react";
import profilImage from "./../../assets/images/asad-tshirt.jpg";
import "./About.css";
import Jump from "react-reveal/Jump";

const About = () => {
    return (
        <>
            <Jump>
                <div className="about" id="about">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 col-xl-6 col-lg-6  about-img">
                            <img src={profilImage} alt="Profile_Pic" />
                        </div>
                        <div className="col-xs-12 col-md-6 col-xl-6 col-lg-6 about-content">
                            <h1>About me</h1>
                            <p>
                                My name is Asad, and I am a dedicated MERN stack
                                developer with two years of experience in the
                                field. I completed my Higher Secondary
                                Certificate (HSC) in 2018 and embarked on a
                                journey to master web development. I achieved
                                this by completing the MERN stack program from
                                the "Ostad" community, where I scored over 98%.
                                I have a strong command of HTML, CSS, Bootstrap,
                                JavaScript, Node.js, Express.js, React.js, and
                                MongoDB. My skills are backed by numerous
                                completed projects, which you can find in the
                                project section. I pride myself on working hard
                                and being highly aware of the importance of
                                on-time delivery. In addition to my technical
                                skills, I possess excellent communication
                                abilities and am fluent in Bengali, English,
                                Hindi, and Arabic, with Bengali being my native
                                language. I am continuously learning and
                                exploring new technologies to enhance my
                                expertise in web development. I am committed to
                                delivering high-quality work and exceeding
                                client expectations through dedication and
                                continuous improvement.
                            </p>
                        </div>
                    </div>
                </div>
            </Jump>
        </>
    );
};

export default About;
