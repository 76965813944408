import { BsFiletypeHtml, BsFiletypeCss, BsBootstrap } from "react-icons/bs";
import { FaNodeJs } from "react-icons/fa";
import {
    SiJavascript,
    SiMui,
    SiReact,
    SiTailwindcss,
    SiFigma,
    SiAdobephotoshop,
    SiMongodb,
    SiMysql,
    SiGithub,
    SiExpress,
} from "react-icons/si";
export const TechstackList = [
    {
        _id: 1,
        name: "HTML",
        icon: BsFiletypeHtml,
    },
    {
        _id: 2,
        name: "CSS",
        icon: BsFiletypeCss,
    },
    {
        _id: 3,
        name: "Bootstrap",
        icon: BsBootstrap,
    },
    {
        _id: 4,
        name: "Javascript",
        icon: SiJavascript,
    },

    {
        _id: 5,
        name: "React JS",
        icon: SiReact,
    },

    {
        _id: 7,
        name: "Tailwind ",
        icon: SiTailwindcss,
    },
    {
        _id: 8,
        name: "Figma ",
        icon: SiFigma,
    },

    {
        _id: 9,
        name: "Photoshop ",
        icon: SiAdobephotoshop,
    },

    {
        _id: 10,
        name: "Node JS ",
        icon: FaNodeJs,
    },
    {
        _id: 11,
        name: "Express JS ",
        icon: SiExpress,
    },

    {
        _id: 12,
        name: "Mongodb ",
        icon: SiMongodb,
    },

    {
        _id: 13,
        name: "Git / Github ",
        icon: SiGithub,
    },
];
