import React from "react";
import { useTheme } from "../../context/ThemeContext";
import Typewriter from "typewriter-effect";
import Resume from "./../../assets/docs/resume.txt";
import { BsFillMoonStarsFill, BsFillSunFill } from "react-icons/bs";
import ScrollToTop from "react-scroll-to-top";
import "./home.css";
import Fade from "react-reveal/Fade";

const Home = () => {
    const [theme, setTheme] = useTheme();
    // handle theme
    const handleTheme = () => {
        setTheme((prevState) => (prevState === "light" ? "dark" : "light"));
    };
    return (
        <>
            <div className="container-fluid home-container" id="home">
                <div className="theme-btn" onClick={handleTheme}>
                    {theme === "light" ? (
                        <BsFillMoonStarsFill
                            size={30}
                            
                        />
                    ) : (
                        <BsFillSunFill size={30} className="dark-bg-icon"/>
                    )}
                </div>
                <div className="container home-content">
                    <Fade right>
                        <h2>HI 👋 I'M ASAD</h2>
                        <h1>
                            <Typewriter
                                options={{
                                    strings: [
                                        "A FullStack Developer !",
                                        "A MernStack Developer !",
                                    ],
                                    autoStart: true,
                                    loop: true,
                                }}
                            />
                        </h1>
                    </Fade>
                    <Fade bottom>
                        <div className="home-buttons">
                            <a
                                href="https://api.whatsapp.com/send?phone=97450285571"
                                className="btn btn-hire"
                                rel="noreferrer"
                                target="_blank"
                            >
                                Hire Me
                            </a>
                            <a
                                className="btn btn-cv"
                                href={Resume}
                                download={"your_name.pdf"}
                            >
                                Download Resume
                            </a>
                        </div>
                    </Fade>
                </div>
            </div>
            <ScrollToTop
                smooth
                color= {theme === "light" ? "black": "white"}
                style={{
                    backgroundColor:
                        theme === "light" ? "white" : "black",
                    borderRadius: "80px",
                    border: "2px solid black",
                }}
            />
        </>
    );
};

export default Home;
