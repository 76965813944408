import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";
import Rotate from "react-reveal/Rotate";
import LightSpeed from "react-reveal/LightSpeed";
import { BsFacebook, BsGithub, BsLinkedin } from "react-icons/bs";
import contactImg from "../../assets/images/contact.jpg"

const Contact = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm("service_0h1vvug", "template_958ykvr", form.current, {
                publicKey: "P2UgSTIc54TUXFYQe",
            })
            .then(
                () => {
                    console.log("SUCCESS!");
                    alert("Message Sent Successfully.");
                },
                (error) => {
                    console.log("FAILED...", error.text);
                }
            );
    };

    return (
        <>
            <div className=" contact" id="contact">
                <div className="card card0 border-0 p-3">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12">
                            <div className="card1">
                                <div className="row border-line">
                                    <LightSpeed>
                                        <img
                                            src={contactImg}
                                            alt="contact"
                                            className="image"
                                        />
                                    </LightSpeed>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <Rotate>
                                <div className="card2 card d-flex border-0 px-4 py-5">
                                    <div className="row">
                                        <div className="row">
                                            <h6>
                                                Contact With
                                                <a href="https://www.linkedin.com/in/asad-nabil-aa1193264">
                                                    <BsLinkedin
                                                        color="#0077B5"
                                                        className="ms-2"
                                                        size={30}
                                                    />
                                                </a>
                                                <a href="https://github.com/asadullahbd">
                                                    <BsGithub
                                                        color="#181717"
                                                        className="ms-2"
                                                        size={30}
                                                    />
                                                </a>
                                                <a href="https://www.facebook.com/alibabayoutuber/">
                                                    <BsFacebook
                                                        color="#1877F2"
                                                        className="ms-2"
                                                        size={30}
                                                    />
                                                </a>
                                            </h6>
                                        </div>

                                        <div className="row px-3 mb-4">
                                            <div className="line"></div>
                                            <small className="or text-center">
                                                OR
                                            </small>
                                            <div className="line"></div>
                                        </div>
                                        
                                        <div className="code-from-emailjs">
                                            <form
                                                ref={form}
                                                onSubmit={sendEmail}
                                            >
                                                <input
                                                    type="text"
                                                    name="user_name"
                                                    placeholder="Name..."
                                                />
                                                <input
                                                    type="email"
                                                    name="user_email"
                                                    placeholder="Email..."
                                                />
                                                <textarea
                                                    name="message"
                                                    placeholder="Message..."
                                                />
                                                <input
                                                    type="submit"
                                                    value="Send"
                                                    className="btn"
                                                />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </Rotate>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
