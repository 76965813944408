import React from "react";
import { SiReact } from "react-icons/si";
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./WorkExp.css";

const WorkExp = () => {
    return (
        <>
            <div className=" work" id="workExperience">
                <div className="container work-exp">
                    <h2 className="col-12 mt-3 mb-1 text-center text-uppercase">
                        Work Experience
                    </h2>
                    <hr />
                    <VerticalTimeline lineColor="#1e1e2c">
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: "white", color: "#000", border: "2px solid #333333"}}
                            contentArrowStyle={{ borderRight: "7px solid  #333333" }}
                            date="2022-2023"
                            iconStyle={{ background: "#333333", color: "#fff" }}
                            icon={<SiReact />}
                        >
                            <h3 className="vertical-timeline-element-title">
                                Full Stack Developer
                            </h3>
                            <h4 className="vertical-timeline-element-subtitle">
                                Local Area
                            </h4>
                            <p>
                            I developed several web applications for local shopkeepers, including an e-commerce application, an inventory management system, and a cash management application.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: "white", color: "#000", border: "2px solid #333333"}}
                            contentArrowStyle={{ borderRight: "7px solid  #333333" }}
                            date="2023-2024"
                            iconStyle={{ background: "#333333", color: "#fff" }}
                            icon={<SiReact />}
                        >
                            <h3 className="vertical-timeline-element-title">
                                Full Stack Developer
                            </h3>
                            <h4 className="vertical-timeline-element-subtitle">
                                Gen Z it solution
                            </h4>
                            <p>
                            I worked at Gen Z IT Solutions for a year as a MERN stack developer, enhancing my skills in MongoDB, Express, React, and Node.js while contributing to various web projects and collaborating with a talented team to deliver high-quality solutions.
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: "white", color: "#000", border: "2px solid #333333"}}
                            contentArrowStyle={{ borderRight: "7px solid  #333333" }}
                            date="2024 - present"
                            iconStyle={{ background: "#333333", color: "#fff" }}
                            icon={<SiReact />}
                        >
                            <h3 className="vertical-timeline-element-title">
                                Full Stack Developer 
                            </h3>
                            <h4 className="vertical-timeline-element-subtitle">
                                Fiver
                            </h4>
                            <p>
                            Since 2024, I have been working as a freelancer on Fiverr.
                            </p>
                        </VerticalTimelineElement>
                    </VerticalTimeline>
                </div>
            </div>
        </>
    );
};

export default WorkExp;
