import React from "react";
import "./Menus.css";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import { Link } from "react-scroll";
import {
    FcHome,
    FcAbout,
    FcPortraitMode,
    FcBiotech,
    FcReadingEbook,
    FcVideoProjector,
    FcBusinessContact,
} from "react-icons/fc";
import profilePic from "./../../assets/images/asad-tshirt.jpg";
// import { useTheme } from "../../context/ThemeContext";

const Menus = ({ toggle }) => {
    // const [theme] = useTheme();
    return (
        <>
            {toggle ? (
                <>
                    <Zoom>
                        <div className="navbar-profile-pic">
                            <img src={profilePic} alt="profile pic" />
                        </div>
                    </Zoom>
                    <Fade left>
                    <div className="nav-items">
                        <div className="nav-item">
                            <div className="nav-link">
                                <Link
                                    to="home"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                >
                                    <FcHome /> Home
                                </Link>
                            </div>
                            <div className="nav-link">
                                <Link
                                    to="about"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                >
                                    <FcAbout /> About
                                </Link>
                            </div>
                            <div className="nav-link">
                                <Link
                                    to="education"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                >
                                    <FcReadingEbook /> Education
                                </Link>
                            </div>

                            <div className="nav-link">
                                <Link
                                    to="techstack"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                >
                                    <FcBiotech /> Skills
                                </Link>
                            </div>

                            <div className="nav-link">
                                <Link
                                    to="projects"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                >
                                    <FcVideoProjector /> Projects
                                </Link>
                            </div>
                            <div className="nav-link">
                                <Link
                                    to="workExperience"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                >
                                    <FcPortraitMode /> Work Experience
                                </Link>
                            </div>
                            <div className="nav-link">
                                <Link
                                    to="contact"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                >
                                    <FcBusinessContact /> Contact
                                </Link>
                            </div>
                        </div>
                    </div>
                    </Fade>
                </>
            ) : (
                <>
                    <div className="nav-items" >
                        <div className="nav-item">
                            <div className="nav-link">
                                <Link
                                    to="home"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                >
                                    <FcHome />
                                </Link>
                            </div>
                            <div className="nav-link">
                                <Link
                                    to="about"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                >
                                    <FcAbout />
                                </Link>
                            </div>
                            <div className="nav-link">
                                <Link
                                    to="education"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                >
                                    <FcReadingEbook />
                                </Link>
                            </div>

                            <div className="nav-link">
                                <Link
                                    to="techstack"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                >
                                    <FcBiotech />
                                </Link>
                            </div>

                            <div className="nav-link">
                                <Link
                                    to="projects"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                >
                                    <FcVideoProjector />
                                </Link>
                            </div>
                            <div className="nav-link">
                                <Link
                                    to="workExperience"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                >
                                    <FcPortraitMode />
                                </Link>
                            </div>
                            <div className="nav-link">
                                <Link
                                    to="contact"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                >
                                    <FcBusinessContact />
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Menus;
